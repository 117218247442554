import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./Components/Auth/Login";
import Home from "./Components/Home/Index";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {generarTema} from './Generales/Theme'
import {ErrorPage} from "./Generales/Errores";
import {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from "./Generales/AuthContext";
import GeneralProvider from './Generales/GeneralContext'
import ProtectedRoute from "./Generales/ProtectedRoute";
import Container from "./Layout/Container";
import Busqueda from "./Components/Busqueda";
import Contrato from "./Components/Contrato";
import Interprete from "./Components/Interprete";
import Sello from "./Components/Sello";
import Album from "./Components/Album";
import Usuario from "./Components/Usuario";
import "video-react/dist/video-react.css"; // import css
import Genero from "./Components/Genero";

function App() {
    const [colorTema, setColorTema] = useState(false)

    useEffect(() => {
        const auxColor = localStorage.getItem("ColorTheme");
        if (auxColor === undefined || auxColor === null) {
            localStorage.setItem("ColorTheme", colorTema);
        } else {
            setColorTema(JSON.parse(auxColor));
        }

    }, []);


    return (
    <AuthProvider>
    <GeneralProvider>
        <ThemeProvider theme={generarTema(colorTema)}>                
            <ToastContainer autoClose={5000} closeOnClick/>
            <BrowserRouter>
            <CssBaseline/>
                <Container setColorTema={setColorTema} colorTema={colorTema}>
                    <Routes>
                        <Route path={'/'} element={<Login />}/>                                                                                                                
                        <Route path={'*'} element={<ErrorPage  />}/>
                        <Route path={'/home'} element={<Home />}/>
                        <Route element={<ProtectedRoute/>}> 

                            <Route path={'/busqueda'} element={<Busqueda />} />
                            <Route path={'/contrato'} element={<Contrato />}/>     
                            <Route path={'/agrupacion'} element={<Interprete />}/>      
                            <Route path={'/album'} element={<Album />}/>     
                            <Route path={'/sello'} element={< Sello/>}/>      
                            <Route path={'/usuario'} element={<Usuario/>}/>     
                            <Route path={'/genero'} element={<Genero />}/>                                                  
                        </Route>                                                
                    </Routes>
                </Container>
            </BrowserRouter>
        </ThemeProvider>
    </GeneralProvider>
    </AuthProvider>);

}

export default App;
