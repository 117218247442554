import { Card, CardContent, Grid, IconButton,    
    LinearProgress, TextField, Tooltip
} from "@mui/material";
import React, {useEffect, useState} from "react";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken} from "../../funciones/ClienteAxios";
import {useNavigate} from "react-router-dom";
import {isValidEmail, successToast, warningToast} from "../../Generales/Tools";
import axios from 'axios';
import {Search as SearchIcon} from "@mui/icons-material";
import ReplayIcon from '@mui/icons-material/Replay';
import TableUsers from "./TableUsers";
import NewEditUsu from "./NewEditUsu";

const Usuario = () => {

    const source = axios.CancelToken.source();
    const usuId = localStorage.getItem('UsuId');
    const history = useNavigate();
    const Dispvo = localStorage.getItem('Dispvo');
    const auxDispV = Dispvo.length >= 12 ? Dispvo : '451224452121'
    const [usuarios, setUsuarios] = useState([]);
    const [espera, setEspera] = useState(false)    
    const [selectedRow, setSelectedRow] = useState([])
    const [filtro, setFiltro] = useState('')  
    const [modalAbierto, setModalAbierto] = useState(false);    
    const [recarga, setRecarga] = useState(false); 

    useEffect(() => {
        if (filtro.length!==0) {
            getListUsu()    
        }else{
            setUsuarios([])
        }
        
       // listarTipo()
       // clearCache()
    }, [recarga])

    function getListUsu() {
        const data = qs.stringify({
            Usuario: usuId,
            Cadena: filtro,
            idDispositivo: '45vf2d1vdf21d2f'
        });

        const url = "usuario-list";

        function respuesta(response) {
            if (response[0].Id !== -1) {
                setUsuarios(response)           
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history,source);
    }

    function editarEstatus(row) {
        const data = qs.stringify({
            idUsuario: row.Id,
            Status: row.Status,
            idDispositivo: auxDispV
        });

        const url = "usuario-editar-status";

        function respuesta(response) {
            if (response.respuesta === 1) {
                successToast('El estatus de ' + row.Nombre + ' se edito correctamente.')
            } else {
                warningToast('Ocurrio un error.')
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history,source);
    }

    const Buscar = () => {
        setRecarga(!recarga)
        //let aux = usuarios.filter((item) => item.Nombre.toLowerCase().includes(filtro.toLowerCase()))
        //setUsuarios(aux)
    }

    const Refrescar = () => {
        setFiltro('')
    }

    const abrirModalNuevo = (event) => {
        event.preventDefault()
        setModalAbierto(true)              
    }
 
    const handleEditarEstatus = (id) => {
        let row = usuarios.find(x => x.Id === id)
        setSelectedRow(row)
        //setRegistro({...registro, status: row.Status === 1 ? 0 : 1})
        if(row.Status === 1){
            row.Status = 0
        }else{
            row.Status = 1
        }
       // console.log(row);
         editarEstatus(row)
        let posicion = usuarios.findIndex((element) => element.Id === id)
        usuarios.splice(posicion, 1, row)
    }
    

    const handleFiltro = (e) => {
        let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) || e.target.value === ' ' || e.target.value === '') {
            setFiltro(e.target.value)
        }
    }

   const modNvo = modalAbierto ?<NewEditUsu modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} 
                                    getListUsu={getListUsu}/>:null

    return (<>
        <Grid item xs={12}>
            {modNvo}
            <Card>
                <CardContent>
                    <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                        <Grid item>
                            <TextField variant={'outlined'} name={'Buscar'} label={'Buscar por Nombre'} size={'small'}
                                value={filtro} onChange={handleFiltro}
                            />
                        </Grid>
                        <Grid item>
                        <Tooltip title={'Buscar'}>
                            <IconButton color="primary" size="small" component="span" onClick={Buscar}>
                                <SearchIcon/>
                            </IconButton>
                        </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={'Refrescar tabla'}>
                            <IconButton color="primary" size="small" component="span" onClick={Refrescar}>
                                <ReplayIcon/>
                            </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            {espera ? <LinearProgress/> 
            : ( usuarios.length!==0 ? 
                <TableUsers abrirModalNuevo={abrirModalNuevo} usuarios={usuarios} 
                    handleEditarEstatus={handleEditarEstatus} getListUsu={getListUsu}/>
                :null)}
        </Grid>
    </>);
};
export default Usuario;