import {Box, Button, CircularProgress, Grid, InputAdornment, TextField} from "@mui/material";
import logo from '../../Layout/Icons/logo-sonidero.jpeg'
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../Generales/AuthContext";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import {warningToast} from "../../Generales/Tools";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {AccountCircle} from "@mui/icons-material";
import moment from 'moment';

const Login = () => {

    const navigate = useNavigate()

    const {guardarUsuario} = useContext(AuthContext);
    const [datos, setDatos] = useState({user: '', password: ''})
    const [inputType, setInputType] = useState('password');
    const [userError, setUserError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [espera, setEspera] = useState(false)

    useEffect(() => {
        const usu = localStorage.getItem('UsuarioNom');
        const nvl = localStorage.getItem('Tipo');
        const fecha = localStorage.getItem('fch22');
        let bandera=false
        if (fecha)
        {   let fechaMod=moment(fecha).add(24,'h').format("YYYY-MM-DDTHH:mm")
            let hoy=moment( ).format("YYYY-MM-DDTHH:mm")
            if (fechaMod>hoy)
            {   bandera=true
            }
        }
        if ( usu !== null && bandera )
        {
            guardarUsuario({Nombre: usu, nivel: nvl});
            navigate("/agrupacion");
        }
        else {
            localStorage.clear();
            guardarUsuario([])
            navigate("/");
        }
    }, []);

    function authUser(user) {

        axios.post(process.env.REACT_APP_LINK + 'autenticar/v1/adm/web', {
            user: user.user, pass: user.password, idDispositivo: "987ygubhjnjik"
        }).then(function (response) {
            if (response.status === 200) {
                let auxCode = uuidv4()
                localStorage.setItem('UsuarioNom', response.data.userNombre);
                localStorage.setItem('UsuId', response.data.userId);
                localStorage.setItem('Tipo', response.data.userTipo);
                localStorage.setItem('token20', response.data.userToken);
                localStorage.setItem('Dispvo', auxCode.slice(auxCode.lastIndexOf("-") + 1, auxCode.length));
                localStorage.setItem('fch22',moment().format('YYYY-MM-DDTHH:mm'));
                localStorage.setItem('UserOperacion',response.data.userOperacion);
                guardarUsuario({Nombre: response.data.userNombre, nivel: response.data.userTipo});
                setEspera(false)
                navigate('/agrupacion')
            } else {
                setEspera(false)
                warningToast('Usuario o Contraseña incorrectos')
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleUser = (event) => {
        setUserError('')
        setPasswordError('')
        setDatos({...datos, [event.target.name]: event.target.value});
    }

    const handleLogin = (event) => {
        setEspera(true)
        event.preventDefault();
        if (datos.password === '' || datos.user === '') {
            if (datos.password === '') {
                setPasswordError('Debe ingresar una contraseña')
                setEspera(false)
            }
            if (datos.user === '') {
                setUserError('Debe ingresar un usuario')
                setEspera(false)
            }
        } else {
            authUser(datos)
        }
    }

    const changeVisibility = () => {
        setInputType(inputType === 'password' ? 'text' : 'password')
    }

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'}
              style={{width: '100vw', height: '100vh'}}>
            <Grid item xs={8} md={3}>
                <form onSubmit={handleLogin} noValidate autoComplete={'off'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <img src={logo} width={'80%'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="user" name="user" label="Usuario" variant="standard"
                                       error={userError !== '' ? true : false} helperText={userError} fullWidth
                                       onChange={handleUser}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start"> <AccountCircle/> </InputAdornment>
                                           ),
                                       }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="password" name="password" label="Contraseña" variant="standard"
                                       type={inputType} error={passwordError !== '' ? true : false}
                                       helperText={passwordError} fullWidth onChange={handleUser}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   {inputType === 'password' ?
                                                       <VisibilityOffIcon onClick={changeVisibility}/> :
                                                       <VisibilityIcon onClick={changeVisibility}/>}
                                               </InputAdornment>
                                           ),
                                       }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {espera ? <Box> <CircularProgress/> </Box> :
                                <Button variant={'contained'} color={'primary'} fullWidth
                                        type={'submit'}>Ingresar</Button>}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>);
};

export default Login;
