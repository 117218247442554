import React,{useState} from 'react';
import {IconButton, Switch, Table, TableBody, TableCell, TableContainer, 
    TableHead, Tooltip, Box,LinearProgress} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import NewEditUsu from "./NewEditUsu";
import {ErrorGeneral, llamaApiCargaToken} from "../../funciones/ClienteAxios";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import { makeStyles } from 'tss-react/mui'
import TablaPerfilTipo from './TablaPerfTipo';
import TablaPerfilOperacion from './TablaPerfOpera';
import qs from 'qs';
const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '50rem',
        },
        container: {
            maxHeight: 420,
           // width: '50rem',
        },
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: "#0F82EE",
                color:"white"
            }
        },
        tableCell: {
            "$selected &": {
                color: "#0F82EE",
                color:"white"
            }
        },
        hover: {},
        selected: {},

    };
  });

const TableUsers = ({abrirModalNuevo, usuarios,  handleEditarEstatus, getListUsu}) => {
    const source = axios.CancelToken.source();
    const usuId = localStorage.getItem('UsuId');
    const history = useNavigate();
    const classes = useStyles();
    const [modalEdit, setModalEdit] = useState(false);   
    const [selectedRow, setSelectedRow] = useState([])
    const [registro, setRegistro] = useState([])
    const [listPermUsr, setListPermUsr] = useState([]);   
    const [tipoSelect, setTipoSelect] = useState([]);   
    const [espera, setEspera] = useState(false);

    const llamaPermisos = (llave) => {         
        let data = qs.stringify({                
            usuario: usuId,      
            llave:llave,   
            idDispositivo: 'rjvikcmkcmsdkdcdsmc'
        });

        let url = '/usuario-permiso-list';

        function response(auxiliar) 
        {            
            if (auxiliar.length !== 0) 
            {   setListPermUsr(auxiliar)                                                                                           
            }                                                
        }       
        llamaApiCargaToken( data ,url, response, ErrorGeneral, setEspera, history, source);             
    }

    const handleEditar = (id) => {
        let row = usuarios.find(x => x.Id === id)
        setSelectedRow(row)       
        setModalEdit(true)
    }

    const seleccionado=(row)=>{
        setRegistro(row)
        llamaPermisos(row.Id)
    }

    const Renglon=({row, index})=>{
        return(
        <TableRow hover key={index}  onClick={() => { seleccionado(row) }}
        selected={registro?.Id === row.Id}
        classes={{ selected: classes.selected}}
        sx={{
            '&:hover .MuiTableCell-root': {backgroundColor: (theme) => theme.palette.secondary.light,
                color: (theme) => theme.palette.secondary.contrastText,            }, 
            '&:last-child td, &:last-child th': {border: 0},
            "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: (theme) => theme.palette.primary.light,
                "& > .MuiTableCell-root": {
                    color: (theme) => theme.palette.secondary.contrastText,
                }
            }
            //'&:selected .MuiTableRow-root': {backgroundColor: (theme) => theme.palette.primary,},
        }}>
            <TableCell component="th" scope="row" align={'center'} sx={{padding: '0.2rem'}}>
                <Tooltip title={'Editar Usuario'} arrow   placement="top">
                    <IconButton onClick={() => handleEditar(row.Id)} size={'small'} color={'inherit'}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell component="th" scope="row" align={'center'}
                sx={{ padding: '0.2rem', color: row.Usuario === '' ? 'tomato' : null }}
            >
                {row.Usuario === '' ? 'Sin Usuario' : row.Usuario}
            </TableCell>
            <TableCell component="th" scope="row" align={'center'}
                sx={{ padding: '0.2rem', color: row.Nombre === '' ? 'tomato' : null }}
            >
                {row.Nombre === '' ? 'Sin Nombre' : row.Nombre}
            </TableCell>
            <TableCell component="th" scope="row" align={'center'}
                sx={{ padding: '0.2rem', color: row.TipoNom === '' ? 'tomato' : null}}
            >
                {row.TipoNom === '' ? 'Sin Tipo' : row.TipoNom}
            </TableCell>
            <TableCell component="th" scope="row" align={'center'} 
                sx={{ padding: '0.2rem', color: row.Email === '' ? 'tomato' : null}}
            >
                {row.Email === '' ? 'Sin Email' : row.Email}
            </TableCell>
            <TableCell component="th" scope="row" align={'center'} 
                sx={{ padding: '0.2rem', color: row.Status === -1 ? 'tomato' : null }}
            >
                {row.Status === -1 ? 'Usuario inhabilitado' 
                :<Tooltip title={'Cambiar Estatus'} arrow placement="top">
                    <Switch
                        checked={row.Status === 1 ? true : false}
                        size={'small'} onChange={() => handleEditarEstatus(row.Id)}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                </Tooltip>}
            </TableCell>
        </TableRow>
        )
    }

    const Tabla=()=>{
        return(
        <TableContainer component={Paper} sx={{maxHeight: '80vh'}}>
        <Table sx={{width: '100%'}} aria-label="usuarios table">
            <TableHead sx={{ backgroundColor: (theme) => theme.palette.secondary.light, padding: '0.2rem'}}>
                <TableRow>
                    <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText }}> 
                        <Tooltip title={'Nuevo Usuario'} arrow placement="top">
                            <IconButton onClick={abrirModalNuevo} color={'inherit'} size={'small'}>
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText}}>
                        Usuario
                    </TableCell>
                    <TableCell align="center" sx={{padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText}}>
                        Nombre
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText }}>
                        Tipo
                    </TableCell>
                    <TableCell align="center" sx={{padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText }}>
                        Email
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText }}>
                        Estatus
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {usuarios.length === 0 ? 
                    <TableRow hover sx={{
                        '&:hover .MuiTableCell-root, &:hover .MuiTableCell-root span.material-icons-outlined': {
                            backgroundColor: (theme) => theme.palette.secondary.light,
                            color: (theme) => theme.palette.secondary.contrastText,
                        }, '&:last-child td, &:last-child th': {border: 0},
                        }} 
                    >
                        <TableCell component="th" scope="row" sx={{padding: '0.2rem', color: 'red'}}
                            colSpan={"100%"} align={'center'}>
                            <h4>No existen datos </h4>
                        </TableCell>
                    </TableRow>

                : usuarios.map((row, index) => <Renglon row={row} index={index} />)}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    const tabTipo = listPermUsr.length !==0  ? <TablaPerfilTipo datos={listPermUsr}  setTipoSelect={setTipoSelect} tipoSelect={tipoSelect}/> :null
    const modEdit = modalEdit ?<NewEditUsu modalAbierto={modalEdit} setModalAbierto={setModalEdit} 
                                editUsu={selectedRow} getListUsu={getListUsu} />:null
    const tabOpera= tipoSelect.length !==0 && tipoSelect.permisos ? 
                <TablaPerfilOperacion datos={tipoSelect.permisos}  original={usuarios}  
                    tipo={1} usrId={registro.Id} llamaApi={llamaPermisos} /> :null                                
    return (
    <>
        {modEdit}
        <Box display={"flex"} >
            <Box sx={{width:"60%"}}>
                <Tabla/>
            </Box>
         
            {espera ? 
            <Box style={{width:"14rem", marginTop:.8+'rem',marginLeft:.8+'rem'}} > <LinearProgress  color="primary"/> </Box>
            :<Box style={{ marginLeft:1+'rem'}}>{tabTipo}</Box>    }
           
           <Box>
                {tabOpera}
           </Box>
        </Box>
      
        
    </>
    );
};

export default TableUsers;