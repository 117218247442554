import {
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken} from "../../funciones/ClienteAxios";
import {useNavigate} from "react-router-dom";
import FormDialog from "../../Layout/FormDialog";
import {isValidEmail, successToast, warningToast} from "../../Generales/Tools";
import axios from 'axios';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function NewEditUsu({editUsu,modalAbierto, setModalAbierto, getListUsu}) {
    const source = axios.CancelToken.source();
    const usuId = localStorage.getItem('UsuId');
    const history = useNavigate();
    const Dispvo = localStorage.getItem('Dispvo');
    const auxDispV = Dispvo.length >= 12 ? Dispvo : '451224452121'    
    const [tipo, setTipoList] = useState([]);
    const [espera, setEspera] = useState(false)
    const [cargando, setCargando] = useState(true)    
    const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);
    const [inputType, setInputType] = useState('password');
    
    const [nuevoUsuario, setNuevoUsuario] = useState({ falta: '', id: '', idDisp: '', ip: '',
        puesto: '', status: 1, tipo: "", name: '', user: '', pass: '', email: ''})
    const [nameError, setNameError] = useState('')
    const [tipoError, setTipoError] = useState('')
    const [userError, setUserError] = useState('')
    const [passError, setPassError] = useState('')
    const [emailError, setEmailError] = useState('')

    useEffect(() => {        
        listarTipo()    
        if (!editUsu) {
            setNuevoUsuario({ falta: '',id: '', idDisp: '', ip: '',
                puesto: '', status: 1, tipo: "", name: '',
                user: '', pass: '', email: ''
            })    
        }
        else{
            setNuevoUsuario({
                name: editUsu.Nombre, user: editUsu.Usuario, pass: editUsu.Pass, 
                email: editUsu.Email, tipo: editUsu.Tipo, status: editUsu.Status
            })         
        }
        setNameError('')
        setUserError('')
        setPassError('')
        setEmailError('')
       
        
    }, [])

    function listarTipo() {
        let data = qs.stringify({
            Usuario: usuId,
            idDispositivo: '45vf2d1vdf21d2f'
        });

        let url = "usuario-tipo-list";

        function respuesta(response) {
            if (response[0].Id !== -1) {
                const filtrado= response.filter((tipos)=>tipos.Id !==0)
                setTipoList(filtrado)
                setCargando(false)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history,source);
    }

    function crearNuevo() {
        setCargando(true)
        let data = qs.stringify({
            Tipo: nuevoUsuario.tipo,
            Nombre: nuevoUsuario.name,
            Usuario: nuevoUsuario.user,
            Pass: nuevoUsuario.pass,
            Email: nuevoUsuario.email,
            idDispositivo: auxDispV
        });

        let url = "usuario-nuevo";

        function respuesta(response) {
            if (response.respuesta === 1) {
                successToast('El Usuario ' + nuevoUsuario.name + ' se registro correctamente.')
                setModalAbierto(false)
               getListUsu()
            } else {
                warningToast('Ocurrio un error.')
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history,source);
    }

    function editarUsuario() {
        setCargando(true)
        let data = qs.stringify({
            Llave: editUsu.Id,
            Estatus: nuevoUsuario.status,
            Tipo: nuevoUsuario.tipo,
            Nombre: nuevoUsuario.name,
            Usuario: nuevoUsuario.user,
            Pass: nuevoUsuario.pass,
            Email: nuevoUsuario.email,
            idDispositivo: auxDispV
        });

        let url = "usuario-editar";

        function respuesta(response) {
            if (response.respuesta === 1) {
                successToast('El Usuario ' + nuevoUsuario.name + ' se edito correctamente.')
                setModalAbierto(false)
                getListUsu()
            } else {
                warningToast('Ocurrio un error.')
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history,source);
    }

    const handleUsuario = (event) => {
        setNameError('')
        setUserError('')
        setPassError('')
        setEmailError('')
        setBtnDeshabilitado(false)
        setNuevoUsuario({...nuevoUsuario, [event.target.name]: event.target.value});
    }

    const changeVisibility = () => {
        setInputType(inputType === 'password' ? 'text' : 'password')
    }

    const handleNuevoUsuario = (event) => {
        event.preventDefault();
        if (nuevoUsuario.name === '' || nuevoUsuario.user === '' || nuevoUsuario.pass === '' || nuevoUsuario.email === '') {
            setBtnDeshabilitado(true)
            setCargando(true)
            if (nuevoUsuario.tipo === '') {
                setTipoError('Debe seleccionar un Tipo')
                setCargando(false)
            }
            if (nuevoUsuario.name === '') {
                setNameError('Debe ingresar un nombre')
                setCargando(false)
            }
            if (nuevoUsuario.user === '') {
                setUserError('Debe ingresar un usuario')
                setCargando(false)
            }
            if (nuevoUsuario.pass === '') {
                setPassError('Debe ingresar una contraseña')
                setCargando(false)
            }
            if (nuevoUsuario.email === '') {
                setEmailError('Debe ingresar un email')
                setCargando(false)
            } else {
                if (!isValidEmail(nuevoUsuario.email)) {
                    setEmailError('Debe ingresar un email')
                    setCargando(false)
                }
            }
        } else {
            setBtnDeshabilitado(true)
            setCargando(true)
            if (editUsu) {
                //console.log("Editar usuario");
                editarUsuario()
            } else {
                //console.log("nuevo usuario");
                crearNuevo()
            }
        }
    }

    return (
    <FormDialog tamanio={'sm'}
        titulo={editUsu ? ("Editar Usuario " + editUsu.Nombre) : 'Nuevo Usuario'}
        modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
        btnDeshabilitado={btnDeshabilitado} cargando={espera}
        guardarDatos={handleNuevoUsuario} setCargando={setEspera}
        bgTitle={false}
    >
        <form noValidate autoComplete={'off'}>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth size="small"  error={tipoError !== '' ? true : false} >
                        <InputLabel id="tipo">Tipo de Usuario</InputLabel>
                        <Select
                           //helperText={nameError}
                            labelId="tipo" id="tipo" name="tipo" 
                            value={nuevoUsuario.tipo} label="Tipo de Usuario" onChange={handleUsuario}
                        >
                            {tipo.length !== 0 ? 
                                tipo.map((element, index) =>
                                    <MenuItem key={index} value={element.Id}>{element.Nom}</MenuItem>) 
                            :   <MenuItem value={-1}>Sin Datos</MenuItem>}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField id="name" name="name" label="Nombre" variant="standard"
                        value={nuevoUsuario.name}
                        error={nameError !== '' ? true : false} helperText={nameError}
                        fullWidth
                        onChange={handleUsuario}
                        type={'text'}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField onChange={handleUsuario} id="email" name="email" label="Email"
                        value={nuevoUsuario.email}
                        error={emailError !== '' ? true : false} helperText={emailError}
                        variant="standard" type={'email'}
                        fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <TextField onChange={handleUsuario} id="user" name="user"
                        label="Usuario"
                        variant="standard" value={nuevoUsuario.user}
                        error={userError !== '' ? true : false}
                        helperText={userError}
                        type={'text'} fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <TextField id="pass" name="pass" label="Contraseña" variant="standard"
                        value={nuevoUsuario.pass}
                        type={inputType} error={passError !== '' ? true : false} helperText={passError}
                        fullWidth onChange={handleUsuario}
                        InputProps={ {
                            endAdornment: (
                                <InputAdornment position="start">
                                    {inputType === 'password' ?
                                        <VisibilityOffIcon onClick={changeVisibility}/> :
                                        <VisibilityIcon onClick={changeVisibility}/>}
                                </InputAdornment>
                            )}}
                    
                    />
                </Grid>
            </Grid>
        </form>
    </FormDialog>
  )
}

export default NewEditUsu