import {Grid, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import DaduvaTable from "../../Generales/DaduvaTable";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken} from "../../funciones/ClienteAxios";
import {useNavigate} from "react-router-dom";
import FormDialog from "../../Layout/FormDialog";
import axios from 'axios';
const Home = () => {
    const source = axios.CancelToken.source();
    const [cargando, setCargando] = useState(false);
    const [interpretes, setInterpretes] = useState([]);
    const [modalAbierto, setModalAbierto] = useState(false);
    const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);
    const usuId = localStorage.getItem('UsuId');
    const Dispvo = localStorage.getItem('Dispvo');
    const auxDispV = Dispvo.length >= 12 ? Dispvo : '451224452121'
    const history = useNavigate();
    const [espera, setEspera] = useState(false)

    useEffect(() => {
        let data = qs.stringify({
            Usuario: usuId, idDispositivo: auxDispV
        });

        let url = "interprete-list";

        function respuesta(response) {
            if (response[0].Id !== -1) {
                setInterpretes(response)
                setCargando(false)

            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history,source);
    }, []);


    const handleOpen = (event) => {
        event.preventDefault()
        setModalAbierto(true)
    }

    const columnas = [
        {id: 'Img', label: ''},
        {id: 'Nom', label: 'Nombre', minWidth: 170},
        {id: 'Representa', label: 'Representante', minWidth: 170},
        {id: 'Email', label: 'Email', minWidth: 170},
        {id: 'Tel', label: 'Telefono', minWidth: 170}
    ];

    const rowActions = [{
        id: 'Preview', label: 'Preview', icon: <VideoLibraryIcon />, function: handleOpen, align: 'center', minWidth: 30
    }]

    const colActions = [{
        id: 'Preview', label: 'Preview', icon: <VideoLibraryIcon/>, function: handleOpen, align: 'center', minWidth: 30
    }]


    //El id de las columnas debe llevar el nombre del campo al cual accedera en el objeto

    return (<Grid container>
        <Grid item xs={12}>
            <FormDialog tamanio={'md'}
                        titulo={'Modal de Pruebas'}
                        modalAbierto={modalAbierto}
                        setModalAbierto={setModalAbierto}
                        btnDeshabilitado={btnDeshabilitado}
                        cargando={cargando}
                        setCargando={setCargando}
                        bgTitle={false}
            >
                <TextField id="password" name="password" label="Contraseña" variant="standard"
                           fullWidth
                />
            </FormDialog>
        </Grid>
        <Grid item xs={12}>
            <DaduvaTable columns={columnas} rows={interpretes} initialPage={0} elementsPerPage={10}
                         isDense={true}
                         rowActions={rowActions}
                         colActions={colActions}/>
        </Grid>
        <Grid item xs={12}>
            <DaduvaTable columns={columnas} rows={interpretes}/>
        </Grid>
    </Grid>);
};

export default Home;
